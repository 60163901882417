.App {
  text-align: center;
}

button {
  border-radius: 2px !important;
}

.lineBreak {
  border: 1px solid #619700;
  margin-bottom: 35px;
}

.landingHeader {
  background-color: #ffffff;
  width: 100%;
}

.card-image {
  background-image: url("./clover-station-2-large.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.provider-row {
  min-height: 150px;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 0;
/*{"minHeight": "150px", "width": "80%", "marginRight": "auto", "marginLeft": "auto", }*/
}

.video-row {
  height: 400px;
}

.video {
  max-height: 400px;
  max-width: 600px;
  height: 100%;
  width: 100%
}

.activate-provider-button {
  /*height: 25px;*/
  margin-top: 20px !important;
  padding: 5px;
}

.link-to-director-button {
  padding: 5px;
  min-width: 200px;
}

.card {
  min-height: 200px;
}

.gradient {
  background: linear-gradient(45deg, #619700, #393939) !important;
}

.background-overlay {
  background-color: rgba(57, 57, 57, 0.7);
}

.jumbo-text {
  color: #619700;
}

.welcome {
  background-color: #dfdfdf;
  padding: 3%;
}

.landingBody {
  padding-top: 3%;
  padding-bottom: 1%;
}

.divider {
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.dividerLine {
  margin: auto;
  background-color: #393939;
  width: 2px;
  height: 100%;
}

.verticalBreak {
  background-color: #393939;
  height: 2px;
  width: 80%;
  margin: auto;
}

.vert {
  padding: 0 !important;
}

.col-sm-0 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.bodyItem {
  padding-top: 7% !important;
  padding-bottom: 7% !important;
}

.form {
  width: 80%;
  max-width: 410px;
  margin: 0 auto 10px auto;
}

.btn {
  background-color: #393939;
  border: 1px solid #707070;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  width: 150px;
  margin: 5px;
}

.reset-btn {
  padding: 15px;
}

.col-xs-12 {
  padding: 0;
}

.bold {
  font-weight: bold;
}

.left {
  text-align: left;
  float: left;
}

.right {
  float: right;
}

.button {
  margin: 5px !important;
}

.snippetContainer {
  width: 90%;
  max-width: 1000px;
}

.quicksignup-subscribergroup {
  border: 0.5px solid #7c7c7c;
  border-radius: 4px;
  padding: 10px 10px 10px 13px;
  display: inline-block;
  margin: 5px;
  color: #7c7c7c;
  font-weight: 500;
  cursor: pointer;
  flex-wrap: wrap;
}

.quicksignup-subscribergroup.selected {
  background-color: #619700;
  color: white;
  border: none;
  padding: 10px 10px 13px 10px;
  display: inline-block;
  margin: 5px;
  font-weight: 500;
  cursor: pointer;
}

.back-btn {
  background-color: white;
  color: #393939 !important;
  border: 1px solid #393939 !important;
  padding: 8px 20px !important;
  border-radius: 0 !important;
}

.next-btn {
  background-color: #393939 !important;
  color: white !important;
  padding: 8px 20px !important;
  border-radius: 0 !important;
  /*border: 1px solid #393939 !important;*/
}

.btn-pair {
  margin: 0px 4px !important;
}

.snippetContainer {
  width: 90%;
  max-width: 1000px;
}

.record-engagement-container {
  width: 90%;
  max-width: 600px;
}

.table-container {
  width: 90%;
  max-width: 1100px;
}

.center {
  text-align: center;
  margin-right: auto !important;
  margin-left: auto !important;
}

.center-spinner {
  text-align: center;
  margin: 30px auto 25px auto;
  max-width: 40px;
}

.spinner {
  padding: 0px;
}

a {
  color: #619700;
}

.cart-summary-card {
    max-width: 500px;
    min-height: 150px;
    border: 1px solid #393939;
    border-radius: 3px;
    margin: 15px auto;
    box-shadow: none;
}

.cart-summary-card-header {
  text-align: left;
  padding: 5px 20px;
  background-color: #393939;
  color: white;
  font-weight: 400;
}

.cart-summary-card-body {
  color: #393939;
}

.cart-summary-table thead{
  background-color: #393939;
  font-weight: 400;
  color: white;
  border-radius: 2px;
}

.cart-summary-table table td {
  font-size: 1rem;
  font-weight: 400;
}

.checkbox-gray [type="checkbox"]:checked+label:before {
  border-color: transparent #393939 #393939 transparent;
}

.form-check-input[type="checkbox"]:checked + label:before {
  border-right: 2px solid #93C23E !important;
  border-bottom: 2px solid #93C23E !important;
}

.md-form input[type=text]:focus:not([readonly]) + label,
.md-form input[type=password]:focus:not([readonly]) + label,
.md-form input[type=datetime]:focus:not([readonly]) + label,
.md-form input[type=datetime-local]:focus:not([readonly]) + label,
.md-form input[type=date]:focus:not([readonly]) + label,
.md-form input[type=month]:focus:not([readonly]) + label,
.md-form input[type=time]:focus:not([readonly]) + label,
.md-form input[type=week]:focus:not([readonly]) + label,
.md-form input[type=number]:focus:not([readonly]) + label,
.md-form input[type=url]:focus:not([readonly]) + label,
.md-form input[type=search]:focus:not([readonly]) + label,
.md-form input[type=tel]:focus:not([readonly]) + label,
.md-form input[type=color]:focus:not([readonly]) + label,
.md-form input[type=email]:focus:not([readonly]) + label {
  color: #619700;
}

.md-form input[type=text]:focus:not([readonly]),
.md-form input[type=password]:focus:not([readonly]),
.md-form input[type=datetime]:focus:not([readonly]),
.md-form input[type=datetime-local]:focus:not([readonly]),
.md-form input[type=date]:focus:not([readonly]),
.md-form input[type=month]:focus:not([readonly]),
.md-form input[type=time]:focus:not([readonly]),
.md-form input[type=week]:focus:not([readonly]),
.md-form input[type=number]:focus:not([readonly]),
.md-form input[type=url]:focus:not([readonly]),
.md-form input[type=search]:focus:not([readonly]),
.md-form input[type=tel]:focus:not([readonly]),
.md-form input[type=color]:focus:not([readonly]),
.md-form input[type=email]:focus:not([readonly]) {
  box-shadow: 0 1px 0 0 #619700;
  border-bottom: 1px solid #619700;
}

input {
  color: #495057;
}

.select-wrapper label:focus:not([readonly]) {
  color: #619700;
}

.md-form label.active {
  color: #619700;
}

.text-primary {
  color: #619700 !important;
}

.dropdown-content li:hover span:hover{
  background-color: #393939;
  color: #dfdfdf !important;
}

.form-control:focus {
  border-color: #619700;
  box-shadow: 0 2px 4px 0 #619700, 0 1px 3px 0 #619700;
}

.form-row>[class*=col-] {
  padding: 5px 5px 0 5px;
}

.form-fields {
  margin: auto;
}

.create-login {
  color: black;
}

.font {
  font-weight: 400;
}

a:hover {
    color: #393939;
}

.dropdown-content {
  height: 10px;
}

.dropdown-content.fadeIn {
  height: 200px;
}

.error-text {
  color: red;
  margin: 10px 0;
}

.number-button {
  height: 90px;
  background-color: #cecece !important;
  border: 1px solid #c0c0c0 !important;
  border-radius: 0 !important;
  width: 100%;
  color: black !important;
}

.tooltip {
  margin-top: 5px;
  float: right;
}

.domain-row {
  height: 50px;
}

.applied-coupons-list {
  border: 1px solid #393939;
  border-radius: 2px;
  
}

.table-wrapper-div::-webkit-scrollbar {
  display: none
}

.table-wrapper-div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.striped tr:nth-child(even) {
  background-color: #f2f2f2;
}

.react-table-cells {
  padding: 10px !important;
  color: #808080 !important;
}

.react-table-cells.selected {
  padding: 10px !important;
  color: white !important;
}

.react-table-cells .md-form.form-group {
  margin: 0;
}

.clickable-row {
  cursor: pointer;
}

.clickable-row:hover {
  background-color: #d1d1d1 !important;
}

.clickable-row.selected:hover {
  background-color: #405d00 !important;
}

.clickable-row.selected {
  background-color: #619700;
  color: white !important;
}

.table-text {
  margin-bottom: auto;
}

.no-style-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.MuiDialog-paper {
  min-height: 250px;
  padding-bottom: 15px;
  max-height: 700px !important;
}

.bl-coupon {
  line-height: 1;
  border: none;
  border-radius: 10px;
  /*width: 75%;*/
  min-width: 200px;
  /*max-width: 300px !important;*/
  padding: 12px;
  cursor: pointer;
  background-color: #ececec;
  /*margin: 5px !important;*/
  min-height: 110px;
}

.bl-coupon-name {
  font-weight: bold;
}

.bl-coupon-code {
  padding: 8px;
  border-radius: 5px;
  border: darkgray 1px solid;
  margin: 0 16px;
}

.bl-coupon-container {
  max-width: 75% !important;
  margin-top: 20px !important;
}


.applied-coupons-list {
  border: 1px solid #393939;
  border-radius: 2px;

}

.table-wrapper-div::-webkit-scrollbar {
  display: none
}

.table-wrapper-div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.striped tr:nth-child(even) {
  background-color: #f2f2f2;
}

.react-table-cells {
  padding: 10px !important;
  color: #808080 !important;
}

.react-table-cells.selected {
  padding: 10px !important;
  color: white !important;
}

.react-table-cells .md-form.form-group {
  margin: 0;
}

.clickable-row {
  cursor: pointer;
}

.clickable-row:hover {
  background-color: #d1d1d1 !important;
}

.clickable-row.selected:hover {
  background-color: #405d00 !important;
}

.clickable-row.selected {
  background-color: #619700;
  color: white !important;
}

.MuiDialog-paper {
  min-height: 250px;
  padding-bottom: 15px;
  max-height: 700px !important;
}

.no-style-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.table-button {
  padding: 3px 16px !important;
  font-size: 14px !important;
}

.left {
  text-align: left;
  float: left;
}

.right {
  float: right;
}